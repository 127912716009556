import React from 'react';

import '../CTA/CTA.scss';

import checkExtraProps from '@jam3/react-check-extra-props';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import CTALink from '../CTA/CTALink';
import SignedPaperSVG from '../SignedPaperSVG/SignedPaperSVG';

import { customPropTypes, isBrowser, sanitizer } from '../../util';

export default function Thanks({ data }) {
  const { content, cta, title } = data;
  const pullNumber = isBrowser && window?.localStorage?.getItem('cla_pull');

  return (
    <div>
      <div className="svg-container">
        <SignedPaperSVG />
      </div>
      <div className="content">
        <h3>{title}</h3>
        <div className="description" dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
        {pullNumber ? (
          <CTALink text={cta.text} link={`https://github.com/libra/libra/pull/${pullNumber}`} />
        ) : (
          <CTALink text={cta.text} link={cta.link} />
        )}
      </div>
    </div>
  );
}

Thanks.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    content: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
});

export const fragments = graphql`
  fragment Thanks on WpPage_Flexiblelayout_FlexibleChildren_Thanks {
    content
    cta {
      text
      link
    }
    title
  }

  fragment Thanks_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_Thanks {
    content
    cta {
      text
      link
    }
    title
  }
`;
